export const USER_STATUSES = [
	{ title: 'Inactive', class: 'inactive' },
	{ title: 'Active', class: 'success' },
];

export const USER_GROUP_OPTIONS = [
	'All group',
	'Super admin',
];

export const USER_STATUS_OPTIONS = [
	{ name: 'All Status', value: null },
	{ name: 'Active', value: 1 },
	{ name: 'Inactive', value: 0 },
];

export const USER_TABLE_FIELDS = [
	{ key: 'username', label: 'Username', class: 'w-25' },
	{ key: 'name', label: 'Name', class: 'w-25' },
	{ key: 'storeName', label: 'Store' },
	{ key: 'storeItecId', label: 'Store ID' },
	{ key: 'group', label: 'Group' },
	{ key: 'status', label: 'Status' },
];
