<template>
	<div class="tabs-container">
		<div class="tabs-wrapper">
			<CTabs
				:active-tab="activeTab"
				@update:activeTab="handleActiveTab"
			>
				<CTab title="Permission settings">
					<BaseLoading v-if="edit.isLoading" />
					<FormUserRolePermissionSetting
						v-if="!edit.isLoading"
						:is-submitting="edit.isUpdating"
						:name="edit.data.name"
						:description="edit.data.description"
						:permissions="edit.data.permissions"
						is-edit
						class="mt-5"
						@onConfirm="handleConfirm"
						@onRemove="$refs['modal-confirm-delete-role'].open()"
					/>
				</CTab>
				<CTab title="User list">
					<FormUserRoleUserList />
				</CTab>
			</CTabs>
		</div>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete-role"
			:handle-remove="handleDeleteRole.bind(null, $route.params.id)"
			is-confirm-required
			title="Remove this permission group?"
			description="By removing this, all users in this group will lose the access to manage permission."
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormUserRolePermissionSetting from '@/components/FormUserRolePermissionSetting.vue';
import FormUserRoleUserList from '@/components/FormUserRoleUserList.vue';

export default {
	name: 'UserRoleEdit',
	components: {
		FormUserRolePermissionSetting,
		FormUserRoleUserList,
	},
	data() {
		const activeTab = this.$route.query.activeTab
			? Number(this.$route.query.activeTab)
			: 0;
		return {
			activeTab,
		};
	},
	computed: {
		...mapState('roles', {
			edit: 'edit',
		}),
	},
	async created() {
		const roleId = this.$route.params.id;

		await this.getRole(roleId);

		this.setPageTitle({
			title: this.edit.data.name,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getRole: 'roles/getRole',
			updateRole: 'roles/updateRole',
			deleteRole: 'roles/deleteRole',
		}),
		handleActiveTab(activeTab) {
			this.$router.push({ query: { activeTab } });
		},
		async handleConfirm(data) {
			const roleId = this.$route.params.id;
			const params = {
				...data,
			};

			await this.updateRole({ id: roleId, params });
		},
		async handleDeleteRole() {
			const roleId = this.$route.params.id;
			await this.deleteRole(roleId);
		},
	},
};
</script>
