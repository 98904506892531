<template>
	<div>
		<div class="main-wrapper mt-5 mb-5">
			<div class="bg-gray-100 p-3 rounded">
				<CForm
					class="d-flex"
					@submit.prevent="handleAddUser"
				>
					<div class="flex-fill mr-3">
						<CInput
							v-model.trim="formData.user"
							label="Add user"
							placeholder="Email, Username or User ID"
						/>
					</div>
					<div>
						<CButton
							:disabled="users.isSubmitting || !formData.user"
							type="submit"
							color="secondary"
							class="button-add"
						>
							{{ users.isSubmitting ? 'Adding' : 'Add' }}
						</CButton>
					</div>
				</CForm>
			</div>
		</div>
		<div class="px-4">
			<CRow>
				<CCol md="8">
					<form
						class="search-form"
						@submit.prevent="handleSearch"
					>
						<CInput
							v-model.trim="queryParams.q"
							data-test-id="search-input"
							placeholder="Search by Group name"
						>
							<template #prepend-content>
								<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
							</template>
						</CInput>
					</form>
				</CCol>
				<CCol md="4">
					<BaseDropDown
						v-model="status"
						:options="USER_STATUS_OPTIONS"
						label="name"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<BaseTable
						:fields="USER_LIST_FIELDS"
						:items="dataTable"
						:is-loading="users.isLoading"
						striped
						class="table-custom"
					>
						<template #no-items-view>
							<div class="empty-table-element">
								<p class="subtitle">
									{{ $t("global.searchNotFound", { field: "users" }) }}
								</p>
							</div>
						</template>
						<template #status="{ item }">
							<CBadge
								:color="USER_STATUSES[item].class"
								class="badge-status"
							>
								{{ USER_STATUSES[item].title }}
							</CBadge>
						</template>
						<template #delete="{ item }">
							<CButton
								variant="ghost"
								@click="showConfirmDialog(item)"
							>
								<CIcon name="cil-trash" />
							</CButton>
						</template>
					</BaseTable>
				</CCol>
			</CRow>
		</div>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete-user"
			:handle-remove="handleRemoveUserFromRole.bind(null, $route.params.id, selectedUser)"
			title="Remove this user from the group?"
			description="By removing this, user will lose the access to all the permissions."
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { USER_STATUSES, USER_STATUS_OPTIONS } from '../enums/users';
import { cleanObject, cloneDeep } from '../assets/js/helpers';

export default {
	name: 'FormUserRoleUserList',
	data() {
		return {
			USER_STATUSES,
			USER_STATUS_OPTIONS,
			selectedUser: null,
			queryParams: {
				q: this.$route.query.q || null,
				is_active: (Number(this.$route.query.is_active) === 0 || Number(this.$route.query.is_active) === 1)
					? Number(this.$route.query.is_active)
					: null,
			},
			formData: {
				user: null,
			},
		};
	},
	computed: {
		...mapState('roles', {
			edit: 'edit',
			users: 'users',
		}),
		USER_LIST_FIELDS() {
			return [
				{ key: 'username', label: 'Username' },
				{ key: 'name', label: 'Name' },
				{ key: 'storeName', label: 'Store name' },
				{ key: 'storeItecId', label: 'Store ID' },
				{ key: 'status', label: 'Status' },
				{ key: 'delete', label: '' },
			];
		},
		dataTable() {
			return this.users.data.map((user) => {
				return {
					id: user.id,
					username: user.username,
					name: user.name,
					storeName: user.storeName,
					storeItecId: user.storeItecId,
					status: user.status,
					delete: user.id,
				};
			});
		},
		status: {
			get() {
				return USER_STATUS_OPTIONS.find((option) => option.value === this.queryParams.is_active);
			},
			set(status) {
				this.queryParams.is_active = status?.value;
				this.updateUrlParams();
			},
		},
	},
	async created() {
		const roleId = this.$route.params.id;
		this.getRoleUsers({
			id: roleId,
			query: this.queryParams,
		});
	},
	methods: {
		...mapActions({
			getRoleUsers: 'roles/getRoleUsers',
			addUserToRole: 'roles/addUserToRole',
			removeUserFromRole: 'roles/removeUserFromRole',
		}),
		updateUrlParams() {
			const activeTab = this.$route.query.activeTab;
			const query = cleanObject({ ...cloneDeep(this.queryParams), activeTab });
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.updateUrlParams();
		},
		async showConfirmDialog(item) {
			this.selectedUser = item;
			this.$refs['modal-confirm-delete-user'].open();
		},
		async handleAddUser() {
			const roleId = this.$route.params.id;
			const user = this.formData.user;
			await this.addUserToRole({ roleId, user });
			this.formData.user = '';
		},
		async handleRemoveUserFromRole(roleId, userId) {
			await this.removeUserFromRole({ roleId, userId });
		},
	},
};
</script>
<style lang="scss" scoped>
	.button-add {
		margin-top: rem(32);
	}
</style>
